import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import withFirebaseAuth, { WrappedComponentProps } from 'react-with-firebase-auth';
import Button from '@material-ui/core/Button';

import logo from './logo.svg';
import './App.scss';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "hostme-d2e4c.firebaseapp.com",
  databaseURL: "https://hostme-d2e4c.firebaseio.com",
  projectId: "hostme-d2e4c",
  storageBucket: "hostme-d2e4c.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const App: React.SFC<WrappedComponentProps> = (props) => {
  const {
    user,
    signOut,
    signInWithGoogle,
    // signInWithEmailAndPassword,
  } = props;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {
          user 
            ? <p>Hello, {user.displayName}</p>
            : <p>Please sign in.</p>
        }
        {
          user
            ? <Button variant='contained' onClick={signOut}>Sign out</Button>
            : <div>
                {/* <Button onClick={signInWithEmailAndPassword}>Sign in with Email</Button> */}
                <Button onClick={signInWithGoogle} variant='contained' color='primary'>Sign in with Google</Button>
              </div>
        }
      </header>
    </div>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
